<template>
  <div v-loading="loaders.exportReport" class="m-0 px-3 pt-4 pb-0">
    <export-report-modal
      @exportReport="exportReportPatients"
    >
    </export-report-modal>
    <div class="d-flex justify-content-between">
      <h1 class="m-0">Pre-Op Patients</h1>
      <base-button size="md" id="export"
                   class="float-right mr-4 mt-2 white-outline-button button-font title-btn-padding"
                   @click="showExportReportModal"
                   type="secondary">
        <img alt="Image placeholder" class="mt--1" src="/img/svg/export.svg">
        Export
      </base-button>
    </div>
    <div v-loading="loaders.response" class="mt-4 cardStyle">
      <div class="d-flex manage-patients-ucf-button-div filter-button-width mx-3">
        <base-input class="d-inline-block mt-3 mr-2 width-285" v-model="request.api.search"
                    label="Search By Name"
                    placeholder="Search..."
                    type="search"
                    @change="searchPatients"
                    @search="searchPatients"
        >
        </base-input>
        <base-button
          type="secondary"
          class="py-2 ml-auto align-self-center font-weight-normal custom-button mt-3"
          @click="$store.commit('showUCFS')">
          <img
            class="mr-2"
            src="/img/svg/filters.svg"
            alt="Filters"
          > Filter <el-badge :value="getFiltersCount" class="filter-count-badge" type="warning"/>
        </base-button>
      </div>
      <el-table
        :data="response.patients"
        row-key="id"
        class="table-responsive table-flush p-3"
        header-row-class-name="thead-light"
        @sort-change="sortMethod"
      >
        <el-table-column label="PATIENT NAME" min-width="140px">
          <template slot-scope="scope">
            <div class="trim-text-overflow">
              <el-tooltip
                class="item" effect="dark"
                :content="hoverPatientInfo(scope.row)"
                placement="bottom-start"
                :visible-arrow="false"
              >
                <router-link :to="{name: 'PatientDetail', params: {id: scope.row.id}}">
                  <span class="color-maroon">{{ scope.row.first_name + ' ' + scope.row.last_name }}</span>
                </router-link>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="SURGERY DATE" prop="surgery_date" width="160px" sortable="custom">
          <template slot-scope="scope">
            <span>{{ formatDate(scope.row.surgery_date) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="JOIN DATE" prop="program_join_date" width="160px" sortable="custom">
          <template slot-scope="scope">
            <span>{{ formatDate(scope.row.program_join_date) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="CHECKLIST (Pre-Op)" width="230px" align="center">
          <template slot-scope="scope" v-if="scope.row.checklists && scope.row.checklists.length > 0">
            <div class="d-flex justify-content-space-between">
              <el-popover v-for="checklist in scope.row.checklists"
                          placement="top-start"
                          trigger="hover"
                          :key="checklist.id"
                          :visible-arrow="true"
                          width="fit-content"
                          popper-class="checklist-popover-max-width"
                          class="checklist-indication-bar"
                          :class="checklistColor(checklist)"
              >
                <a :href="`/checklists/${checklist.user_id}`" slot="reference">
                  <div class="w-100 h-100"></div>
                </a>
                <p class="m-0 w-100 ellipsis-overflow-text"><span class="tooltip-font-bold">Checklist Item: </span>
                  <span class="tooltip-font">{{ checklist.todo_name }}</span></p>
                <p class="m-0"><span class="tooltip-font-bold">Due Date: </span>
                  <span class="tooltip-font">{{ checklist.todo_due_date }}</span></p>
                <p class="m-0">
                  <span class="tooltip-font-bold">Completed Date: </span>
                  <span class="tooltip-font">{{ checklist.is_completed_date }}</span>
                </p>
                <p class="m-0">
                  <span class="tooltip-font-bold">Completed By: </span>
                  <span class="tooltip-font">{{ checklist.checked_by }}</span>
                </p>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="COMPLETE (Pre-Op)" prop="completion_percentage" width="180px" sortable="custom">
          <template slot-scope="scope">
            <span>{{ scope.row.completion_percentage }} %</span>
          </template>
        </el-table-column>
        <el-table-column label="DAYS TO SURGERY" prop="surgery_date" width="220px" sortable="custom">
          <template slot-scope="scope">
            <span>{{ getDaysSinceSurgery(scope.row.surgery_date) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="CURRENT PIPELINE STAGE" width="220px">
          <template slot-scope="scope">
            <span>{{ getPipelineStage(scope.row) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="row">
        <div class="col-12 my-3 px-4">
          <seminar-surgery-footer :pagination="pagination" @changePage="changePage"
                                  @changedPageLength="changedPageLength"/>
        </div>
      </div>
    </div>
    <div class="tempClassUCFS">
      <user-category-sidebar
        :show-surgery-type-only-filter="true"
        :stages="response.stages"
        :surgery-types="response.surgeryTypes"
      />
    </div>
  </div>
</template>

<script>
import SeminarSurgeryFooter from "./SeminarSurgeryFooter";
import Helper from "@/util/globalHelpers";
import {Option, Select, Table, TableColumn} from "element-ui";
import moment from "moment";
import ExportReportModal from "@/views/Pages/Seminar2Surgery/ExportReportModal";
import fileDownload from "@/mixins/fileDownload";
import UserCategorySidebar from "@/views/Components/UserCategorySidebar.vue";

export default {
  name: "SurgeryDatePatients",
  components: {
    UserCategorySidebar,
    SeminarSurgeryFooter,
    ExportReportModal,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      loaders: {
        response: false,
        exportReport: false
      },
      response: {
        patients: [],
        patientTags: [],
        defaultPatientTags: [],
        stages: [],
        surgeryTypes: [],
      },
      pagination: {
        currentPage: 1,
        perPage: 20,
        page: 1,
        lastPage: 1,
      },
      request: {
        api: {
          sortBy: 'surgery_date',
          sortByDir: 'ASC',
          surgeryType: '',
          selectedPatientTags: [],
          search: '',
        },
      },
      filtersCount: 0,
    }
  },
  mounted() {
    let vm = this
    this.getFiltersData()

    this.$root.$on('UserSubCategoryFilterCount', (data) => {
      vm.filtersCount = data
    })

    this.$root.$on('applyUserSubCategoryFilters', (data) => {
      vm.filtersCount = data.filtersCount
      vm.request.api.surgeryType = data.surgeryType
      vm.pagination.currentPage = 1
      vm.request.api.selectedPatientTags = data.obj
      this.getReportPatients(data.obj)
    })
  },
  beforeDestroy() {
    this.$root.$off('UserSubCategoryFilterCount')
    this.$root.$off('applyUserSubCategoryFilters')
  },
  mixins: [
    fileDownload
  ],
  computed : {
    getFiltersCount () {
      return this.filtersCount ?? 0
    }
  },
  methods: {
    /**
     * Get Patients after search
     */
    searchPatients() {
      this.pagination.page = 1
      this.getReportPatients(this.getPatientTagsObject())
    },

    /**
     * Prepare and return user categories object
     * @returns {*[]}
     */
    getPatientTagsObject() {
      let vm = this, patientTagsObj = []
      vm.response.patientTags.forEach(patientTag => {
        if (patientTag.state && !isNaN(patientTag.state)) {
          patientTagsObj.push(patientTag.state)
        }
      })

      return patientTagsObj
    },

    /**
     * Get filters data
     */
    getFiltersData() {
      let vm = this
      const payload = {
        page: 'manage-patients'
      }

      vm.$store.dispatch('_getFilters', payload).then(response => {
        vm.response.patientTags = response.data.data.categories
        vm.response.defaultPatientTags = response.data.data.defaultCategories
        vm.response.stages = response.data.data.stages
        vm.response.surgeryTypes = response.data.data.types_of_surgery

        const userCategoriesObject = {
          categories: vm.response.patientTags,
          defaultCategories: vm.response.defaultPatientTags
        }
        this.$store.commit('setUserSubcategories', userCategoriesObject)

        let selectedPatientTags = []
        vm.response.patientTags.forEach((category, index) => {
          const i = vm.response.defaultPatientTags.findIndex(defaultCategory => {
            if (defaultCategory.user_category_id == category.id) {
              return true
            }
          })
          if (i != -1) {
            selectedPatientTags.push(vm.response.defaultPatientTags[i].user_sub_category_id)
          }
        })

        vm.request.api.selectedPatientTags = selectedPatientTags
        vm.getReportPatients(selectedPatientTags)
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Patient Tags',
          message: message
        })
      })
    },

    /**
     * Get Pipeline Stage Name
     * @param row
     * @returns {*|string}
     */
    getPipelineStage(row) {
      return row?.pipeline_patient?.pipeline_stage?.title ?? ''
    },

    /**
     * Sorting based on which prop is passed and fetch the post op patients
     * @param prop
     * @param order
     */
    sortMethod({prop, order}) {
      let vm = this
      if (order) {
        vm.request.api.sortBy = prop
        vm.request.api.sortByDir = order == 'ascending' ? 'ASC' : 'DESC'
      } else {
        vm.request.api.sortBy = "surgery_date"
        vm.request.api.sortByDir = 'DESC'
      }
      vm.pagination.page = 1
      vm.getReportPatients(vm.getPatientTagsObject())
    },

    /**
     * Return the payload object
     * @param userCategoriesObject
     * @param withPaginate
     * @returns {{patientSubTags: (*|null)}}
     */
    assignParams(userCategoriesObject, withPaginate = true) {
      let vm = this
      let params = {
        ...vm.request.api,
        patientSubTags: userCategoriesObject && userCategoriesObject.length > 0 ? userCategoriesObject : null,
      }

      if (withPaginate) {
        Object.assign(params, {...this.pagination})
      }

      return params
    },

    /**
     * Show the Export Report Modal
     */
    showExportReportModal() {
      this.$store.commit('toggleExportReportModal', true)
    },

    /**
     * Hide the Export Report Modal
     */
    hideExportReportModal() {
      this.$store.commit('toggleExportReportModal', false)
    },

    /**
     * Save the response object
     * @param response
     */
    saveResponse(response) {
      let vm = this
      vm.response.patients = []
      vm.response.patients = [...response.data.data.data]
      vm.pagination.currentPage = response.data.data.current_page
      vm.pagination.lastPage = response.data.data.last_page
      vm.pagination.perPage = response.data.data.per_page
      vm.pagination.page = response.data.data.current_page
      vm.pagination.total = response.data.data.total
    },

    /**
     * Get All the Post Op Patients
     * @param userCategoriesObject
     */
    getReportPatients(userCategoriesObject = null) {
      let vm = this
      vm.loaders.response = true
      const params = vm.assignParams(userCategoriesObject)
      vm.$store.dispatch('_getSurgeryDateReportPatients', params)
        .then(response => {
          vm.saveResponse(response)
        })
        .catch(error => {
            if (!axios.isCancel(error)) {
              let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
              vm.$notify.error({
                title: 'Surgery Date Patients Report',
                message: message
              })
            }
        })
        .finally(() => {
          vm.loaders.response = false
        })
    },

    /**
     * Export the Report Patients
     * @param userCategoriesObject
     */
    exportReportPatients(userCategoriesObject = null) {
      let vm = this
      vm.hideExportReportModal()
      vm.loaders.exportReport = true
      const params = vm.assignParams(userCategoriesObject, false)
      const config = {
        responseType: "blob",
        params
      }
      vm.$store.dispatch('_surgeryDatePatientsExcel', config)
        .then(response => {
          vm.generateExportFileDownload(response, 'Baritastic-All-Surgery-Date-Patients')
        })
        .catch(error => {
          console.log(error)
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Surgery Date Patients Excel',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.exportReport = false
        })
    },

    /**
     * Return the basic information about user
     * @param row
     * @returns {string}
     */
    hoverPatientInfo(row) {
      const isValid = (prop) => row.hasOwnProperty(prop) && row[prop] !== '' && row[prop] != null

      const details = [
        (isValid('first_name') ? row.first_name : '') + ' ' + (isValid('last_name') ? row.last_name : ''),
        isValid('email') ? row.email : '',
        isValid('phone') ? row.phone : '',
        isValid('dob') && !isNaN(Date.parse(row.dob)) ? Helper.extractAndFormatDate(new Date(row.dob).toDateString(), false) : '',
        isValid('mrn_number') ? row.mrn_number : ''
      ].filter(detail => detail !== '')

      return details.join(', ')
    },

    /**
     * Change page in the listing
     * @param page
     */
    changePage(page) {
      if (this.pagination.page != page) {
        this.pagination.page = page
        this.getReportPatients(this.getPatientTagsObject())
      }
    },

    /**
     * Change page length in the listing
     * @param pageLength
     */
    changedPageLength(pageLength) {
      this.pagination.page = 1
      this.pagination.perPage = pageLength
      this.getReportPatients(this.getPatientTagsObject())
    },

    /**
     * Return the formatted surgery date
     * @param date
     * @returns {string|string}
     */
    formatDate(date) {
      return date ? moment(date).format('MMM D, YYYY') == 'Invalid date' ? 'Incorrect Date' : moment(date).format('MMM D, YYYY') : ''
    },

    /**
     * Get string of days since surgery
     * @param date
     * @returns {string|*|string}
     */
    getDaysSinceSurgery(date) {
      const start = new Date()
      const end = new Date(date)

      let years = end.getFullYear() - start.getFullYear()
      let months = end.getMonth() - start.getMonth()
      let days = end.getDate() - start.getDate()

      if (days < 0) {
        months -= 1
        days += new Date(end.getFullYear(), end.getMonth(), 0).getDate() // days in previous month
      }

      if (months < 0) {
        years -= 1
        months += 12
      }

      let result = [];
      if (years > 0) {
        result.push(`${years} year${years > 1 ? 's' : ''}`)
      }
      if (months > 0) {
        result.push(`${months} month${months > 1 ? 's' : ''}`)
      }
      if (days > 0) {
        result.push(`${days} day${days > 1 ? 's' : ''}`)
      }

      return result.join(', ') || '0 days'
    },

    /**
     * Formatting the since surgery string
     * @param date
     * @returns {string|*}
     */
    formatSinceSurgeryDate(date) {
      if (date == 'Invalid date') {
        return ''
      } else if (date.includes('hours ago')) {
        return 'Today'
      } else {
        return date.replace(date.charAt(0), date.charAt(0).toUpperCase())
      }
    },

    /**
     * Get Checklist Color
     * @param checklist
     * @returns {string}
     */
    checklistColor(checklist) {
      let checklistColor = 'checklist-gray'
      const currentDate = moment().format('YYYY-MM-DD')

      if (checklist.checked_by == 'Program') {
        checklistColor = 'checklist-green'
      } else if (checklist.checked_by == 'Patient') {
        checklistColor = 'checklist-yellow'
      } else if ((!checklist.is_completed_date || checklist.is_completed_date == '')
        && currentDate > moment(checklist.todo_due_date).format('YYYY-MM-DD')) {
        checklistColor = 'checklist-red'
      } else if (checklist.checked_by == 'Waiting for paperwork') {
        checklistColor = 'checklist-blue'
      }

      return checklistColor
    },
  }
}
</script>

<style scoped>
.checklist-green {
  background-color: #2DCE89;
}

.checklist-red {
  background-color: #FB537A;
}

.checklist-blue {
  background-color: #557a94;
}

.checklist-yellow {
  background-color: #FFCD16;
}

.checklist-gray {
  background-color: #EDEDED;
}

.checklist-indication-bar {
  height: 4px;
  flex-grow: 1;
  margin: 0 0.05rem 0 0.05rem;
}

.checklist-indication-bar:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.checklist-indication-bar:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.tooltip-font-bold {
  font: normal normal bold 12px/14px Karla;
}

.tooltip-font {
  font: normal normal normal 12px/14px Karla;
}
</style>
